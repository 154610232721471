// npm
import React, { memo } from 'react'
import { Box, BoxProps, Container, useTheme } from '@mui/material'
import { SwiperProps } from 'swiper/react'
// src
import MainHeading, { MainHeadingProps } from '@molecules/headings/MainHeading'
import Subtitle, { SubtitleProps } from '@molecules/headings/Subtitle'
import HorizontalSwiper from '@organisms/swipers/layouts/HorizontalSwiper'

interface Props {
  keyId: string
  wrapperProps: BoxProps
  smallHeading: string
  smallHeadingProps: Omit<SubtitleProps, 'text'>
  mainHeading: string
  children: React.ReactNode
}

const ContentSectionSwiperCards = ({
  wrapperProps,
  keyId,
  smallHeading,
  smallHeadingProps,
  mainHeading,
  children,
}: Props) => {
  const theme = useTheme()

  const swiperStyles = {
    ['.swiper-wrapper']: {
      alignItems: 'stretch',
    },
    ['.swiper-extra']: {
      overflow: 'visible',
      ['.swiper-slide']: {
        height: 'auto',
        display: 'flex',
        alignItems: 'stretch',
      },
    },
    //   [theme.breakpoints.up('md')]: {
    //     paddingLeft: theme.spacing(10),
    //     paddingRight: theme.spacing(10),
    //   },
    //   [theme.breakpoints.up('xl')]: {
    //     paddingLeft: theme.spacing(20),
    //     paddingRight: theme.spacing(20),
    //   },
  }

  const swiperProps: SwiperProps = {
    slidesPerView: 1,
    spaceBetween: 20,
    autoplay: {
      delay: 4000,
      disableOnInteraction: true,
    },
    breakpoints: {
      [theme.breakpoints.values.xs]: {
        slidesPerView: 1,
      },
      [theme.breakpoints.values.md]: {
        slidesPerView: 2,
      },
      [theme.breakpoints.values.lg]: {
        slidesPerView: 3,
      },
    },
  }

  const mainHeadingProps: MainHeadingProps = {
    typographyProps: {
      variant: 'h2',
      color: 'inherit',
      textAlign: 'center',
      lineHeight: '1',
      mb: 0,
      mt: 2,
    },
    lineBreaksProps: {
      keyId: keyId,
      text: mainHeading,
      breakpoint: 'xs',
    },
  }

  return (
    <Box
      component="section"
      pt={{
        xs: 4,
        lg: 10,
      }}
      pb={{
        xs: 4,
        lg: 8,
      }}
      position="relative"
      width="100%"
      overflow="hidden"
      {...wrapperProps}
    >
      <Container maxWidth="md" sx={{ textAlign: 'center' }}>
        <Subtitle
          text={smallHeading}
          typographyProps={{ component: 'h2' }}
          {...smallHeadingProps}
        />
        <MainHeading {...mainHeadingProps} />
      </Container>
      <Container
        maxWidth="xl"
        sx={{
          px: { xs: 3, sm: 8, md: 6, lg: 7, xl: 6 },
          mt: 6,
        }}
      >
        <HorizontalSwiper
          swiperProps={swiperProps}
          wrapperProps={{ swiperStyles: swiperStyles }}
        >
          {children}
        </HorizontalSwiper>
      </Container>
    </Box>
  )
}

export default memo(ContentSectionSwiperCards)
