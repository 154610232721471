// npm
import React, { memo, useRef } from 'react'
import { Box, BoxProps, styled, useTheme } from '@mui/material'
import SwiperCore, { Navigation, Autoplay, Scrollbar } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'
import { Swiper, SwiperProps } from 'swiper/react'
// src
import DirectionButton from '@atoms/buttons/DirectionButton'

interface StyleProps {
  swiperStyles?: Object
}

interface WrapperProps extends StyleProps, BoxProps {}

interface Props extends StyleProps {
  children: React.ReactNode
  wrapperProps?: WrapperProps
  swiperProps?: SwiperProps
}

interface scrollbarPositionProps {
  scrollbarWidth: number
  navSpace: number
  buttonSize: number
  buttonSpace: number
}

const scrollbarWidths = {
  xs: 300,
  md: 480,
  lg: 780,
}

const scrollbarProps = {
  navSpace: 50,
  buttonSize: 80,
  buttonSpace: 8,
}

const getNavContainerWidth = ({
  scrollbarWidth,
  navSpace,
  buttonSize,
  buttonSpace,
}: scrollbarPositionProps): number => {
  const buttonContainerWidth = buttonSize * 2 + buttonSpace
  return scrollbarWidth + buttonContainerWidth + navSpace
}
const getScrollbarPoisition = ({
  scrollbarWidth,
  navSpace,
  buttonSize,
  buttonSpace,
}: scrollbarPositionProps): number => {
  const buttonContainerWidth = buttonSize * 2 + buttonSpace
  return -(scrollbarWidth / 2 + (buttonContainerWidth + navSpace) / 2)
}

const SwiperWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'swiperStyles',
})<StyleProps>(({ swiperStyles, theme }) => ({
  ...(swiperStyles && {
    ...swiperStyles,
  }),
  ['.swiper']: {
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(14),
    },
    [theme.breakpoints.up('xl')]: {
      paddingBottom: theme.spacing(20),
    },
  },
  ['.swiper-horizontal > .swiper-scrollbar']: {
    width: `${scrollbarWidths.xs}px`,
    left: '50%',
    height: '1px',
    backgroundColor: 'rgba(0,0,0,.12)',
    transform: 'translateX(-50%)',
    bottom: '40px',

    [theme.breakpoints.up('md')]: {
      width: `${scrollbarWidths.md}px`,
      transform: `translateX(${getScrollbarPoisition({
        ...scrollbarProps,
        scrollbarWidth: scrollbarWidths.md,
      })}px)`,
    },
    [theme.breakpoints.up('lg')]: {
      width: `${scrollbarWidths.lg}px`,
      transform: `translateX(${getScrollbarPoisition({
        ...scrollbarProps,
        scrollbarWidth: scrollbarWidths.lg,
      })}px)`,
    },
    ['.swiper-scrollbar-drag']: {
      height: '5px',
      marginTop: '-2px',
      backgroundColor: theme.palette.primary.main,
      borderRadius: 0,
    },
  },
}))

const NavContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'absolute',
  bottom: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  width: `${getNavContainerWidth({
    ...scrollbarProps,
    scrollbarWidth: scrollbarWidths.xs,
  })}px`,
  [theme.breakpoints.up('md')]: {
    width: `${getNavContainerWidth({
      ...scrollbarProps,
      scrollbarWidth: scrollbarWidths.md,
    })}px`,
  },
  [theme.breakpoints.up('lg')]: {
    width: `${getNavContainerWidth({
      ...scrollbarProps,
      scrollbarWidth: scrollbarWidths.lg,
    })}px`,
  },
  height: `${scrollbarProps.buttonSize}px`,
}))

const NavButton = styled(DirectionButton)(({ theme }) => ({
  width: `${scrollbarProps.buttonSize}px`,
  height: `${scrollbarProps.buttonSize}px`,
  zIndex: 10,
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}))

const ContentSectionSwiper = ({
  children,
  wrapperProps,
  swiperProps,
}: Props) => {
  const theme = useTheme()
  const prevRef = useRef(null)
  const nextRef = useRef(null)

  SwiperCore.use([Scrollbar, Navigation, Autoplay])
  return (
    <SwiperWrapper {...wrapperProps}>
      <Swiper
        className="swiper swiper-extra"
        onInit={(swiper) => {
          // @ts-ignore
          swiper.params.navigation.prevEl = prevRef.current
          // @ts-ignore
          swiper.params.navigation.nextEl = nextRef.current
          swiper.navigation.init()
          swiper.navigation.update()
        }}
        observer={true}
        observeParents={true}
        scrollbar
        freeMode={{
          sticky: true,
        }}
        {...swiperProps}
      >
        {children}
        <NavContainer>
          <NavButton direction="left" aria-label="previous" ref={prevRef} />
          <NavButton direction="right" aria-label="next" ref={nextRef} />
        </NavContainer>
      </Swiper>
    </SwiperWrapper>
  )
}

export default memo(ContentSectionSwiper)
