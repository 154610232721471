// npm
import React, { memo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { SwiperSlide } from 'swiper/react'

// components
import Card from '@molecules/cards/Card'
import ContentSectionSwiperCards from './ContentSectionSwiperCards'

interface Props {
  keyId: string
  subtitle: string
  mainHeading: string
  text?: string
}

const ContentSectionSwiper = ({
  keyId,
  subtitle,
  mainHeading,
  text,
}: Props) => {
  const d = useStaticQuery<Queries.ContentSectionBlogpost>(graphql`
    query ContentSectionBlogpost {
      allDatoCmsBlogPost(
        limit: 10
        sort: { fields: [isSeoPost, meta___createdAt], order: [DESC, DESC] }
      ) {
        edges {
          node {
            ...cardBlogPost
          }
        }
      }
    }
  `)

  return (
    <ContentSectionSwiperCards
      wrapperProps={{ bgcolor: 'primary.light', color: 'text.primary' }}
      keyId="related-posts"
      mainHeading={mainHeading}
      smallHeading={subtitle}
      smallHeadingProps={{ color: 'primary.main' }}
    >
      {d.allDatoCmsBlogPost.edges.map((item: any) => {
        const post: Queries.DatoCmsBlogPost = item.node
        return (
          <SwiperSlide key={post.originalId}>
            <Card data={post} />
          </SwiperSlide>
        )
      })}
    </ContentSectionSwiperCards>
  )
}

export default memo(ContentSectionSwiper)
